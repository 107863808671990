<template>
  <div v-if="body" class="partners">
    <ItemPartner v-for="partner in body.partners" :key="partner._uid" :partner="partner"/>
  </div>
</template>

<script>
import ItemPartner from "../items/ItemPartner";

export default {
  name: "ContainerParnter",
  components: {ItemPartner},
  props: {
    body: Object
  }
}
</script>

<style scoped lang="scss">

.partners {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 2.5rem;
  padding: 2rem 0;

  @include tablet-down {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;
  }

  @include mobile-down {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2rem;
  }
}


</style>