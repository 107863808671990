import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    images_quer: [],
    images_hoch: [],
    images_loaded: 0
  },
  mutations: {
    setImagesQuer(state, images) {
      images
        .map(img => {
          const transformation = 'tr:ar-3-2,w-1200,q-60,pr-true,bl-12'
          let urlSplit = img.url.split('/')
          urlSplit.splice(4, 0, transformation)
          img.url = urlSplit.join('/')
        })
      state.images_quer = images
        .slice(0)
        .sort(() => Math.random() - 0.5);
    },
    setImagesHoch(state, images) {
      images
        .map(img => {
          const transformation = 'tr:ar-2-3,w-800,q-60,pr-true,bl-12'
          let urlSplit = img.url.split('/')
          urlSplit.splice(4, 0, transformation)
          img.url = urlSplit.join('/')
        })

      state.images_hoch = images
        .slice(0)
        .sort(() => Math.random() - 0.5);
    },
    setLoaded(state, loaded = false) {
      state.images_loaded = loaded ? state.images_loaded + 1 : 0
    },
    setImages(state, images) {
      for (const image of images) {
        if (image.height / image.width >= 1) state.images_hoch.push(image)
        else state.images_quer.push(image)
      }
      // state.images_hoch = images.filter(image => )
      // state.images_quer = images.filter(image => image.height / image.width < 0)
    }
  },
  actions: {
    async fetchImages({commit, state}) {
      if (state.images_loaded <= 0) {
        await fetch(`/.netlify/functions/fetch-images?folder=${'hochformat'}`)
          .then(res => {
            return res.json()
          })
          .then(({images}) => {
            if (images) {
              commit("setImagesHoch", images)
              commit("setLoaded", true)
            }
          })

        await fetch(`/.netlify/functions/fetch-images?folder=${'querformat'}`)
          .then(res => {
            return res.json()
          })
          .then(({images}) => {
            if (images) {
              commit("setImagesQuer", images)
              commit("setLoaded", true)
            }
          })
      }
    },
  },
  getters: {
    IMAGES_QUER: (state) => {
      return state.images_quer
    },
    IMAGES_HOCH: (state) => {
      return state.images_hoch
    },
    IMAGES_LOADED: (state) => {
      return state.images_loaded >= 2
    },
  }
})


