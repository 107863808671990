import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Impressum from "../views/Impressum";
import PageNotFound from "../views/PageNotFound";
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  { path: "*", component: PageNotFound }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
