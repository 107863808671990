import Vue from 'vue'
import Vuex from 'vuex'
import api from "../utils/api";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globals: null,
    loaded: false
  },
  mutations: {
    setGlobals(state, data) {
      state.globals = data
    },
    setLoaded(state) {
      state.loaded = true
    },
  },
  actions: {
    async fetchContent({commit, state}) {
      const {data} = await api.get(`cdn/stories/global`);
      commit('setGlobals', data.story.content)
      commit('setLoaded')
    },
  },
  getters: {
    LOADED: (state) => {
      return state.loaded || false
    },
    GLOBALS: (state) => {
      return state.globals || false
    },
    EXTERNAL_LINKS: (state) => {
      if(!state.globals) return false
      return state.globals.external_links || false
    },
    COPYRIGHT: (state) => {
      if(!state.globals) return false
      return state.globals.copyright || false
    },
    LINK_IMPRESSUM: (state) => {
      if(!state.globals) return false
      return state.globals.link_impressum || false
    },
    LOADING_BACKGROUNDCOLOR: (state) =>{
      if(!state.globals || !state.globals.loading_animation) return false
      return state.globals.loading_animation[0].color || false
    },
    LOADING_LOGO: (state) =>{
      if(!state.globals || !state.globals.loading_animation) return false
      return state.globals.loading_animation[0].logo || false
    },
  }
})


