<template>
  <section v-if="imagesHorizontal">
    <l-map
        ref="map"
        @update:bounds="mapUpdate"
        :minZoom="minZoom"
        :maxZoom="maxZoom"
        :crs="crs"
        style="height: 100vh; width: 100vw;"
        :options="{zoomControl: false, attributionControl: false}">
      <template v-if="imagesLoaded">
        <l-image-overlay
            v-for="(bounds, index) in boundGridHorizontal"
            :key="`img-hor-${index}`"
            :url="getRandomImage('horizontal', bounds)"
            :bounds="boundGridHorizontal[index]"
            :interactive="true"
            @mouseover="hoverImage"
            @click="focusImage"
        />
        <l-image-overlay
            v-for="(bounds, index) in boundGridVertical"
            :key="`img-vert-${index}`"
            :url="getRandomImage('vertical', bounds)"
            :bounds="boundGridVertical[index]"
            :interactive="true"
            @mouseenter="hoverImage"
            @click="focusImage"
        />
      </template>
    </l-map>
    <LoadingScreen :isLoading="!imagesLoaded"/>
  </section>
</template>

<script>
import {CRS} from "leaflet";
import {LImageOverlay, LMap, LRectangle} from "vue2-leaflet";
import imageStore from '../store/images'
import LoadingScreen from "./LoadingScreen";

export default {
  components: {
    LMap,
    LImageOverlay,
    LRectangle,
    LoadingScreen,
  },
  data() {
    return {
      rectangle: {
        bounds: [[12400, 5200], [13800, 6900]],
        style: {color: 'blue', weight: 1}
      },
      minZoom: 0.2, // zoom out
      maxZoom: 2, // zoom in
      bounds: [[1000, 500], [4700, 4500]],
      crs: CRS.Simple,
      offset: [0, 700, 200, 900, 400, 1100, -600, 100, -400, 300, -200, 500],
      rowsMin: 90,
      rowsMax: 94,
      colsMin: 100,
      colsMax: 150,
    };
  },
  computed: {
    imagesHorizontal() {
      return imageStore.getters.IMAGES_QUER || []
    },
    imagesVertical() {
      return imageStore.getters.IMAGES_HOCH || []
    },
    imagesLoaded() {
      return imageStore.getters.IMAGES_LOADED
    },
    rectangleBounds() {
      const x1 = this.rowsMin * 1200 + 400   // init 12300
      const y1 = this.colsMin * 100 + 200   // init 5200
      const x2 = this.rowsMax * 1200 - 600   // init 13800
      const y2 = this.colsMax * 100 - 100   // init 6900
      return [[x1, y1], [x2, y2]]
    },
    boundGridHorizontal() {
      let bounds = []
      for (let i = this.rowsMin; i < this.rowsMax; i++) {
        for (let j = this.colsMin; j < this.colsMax; j++) {
          let y = j * 100 // horizontal
          let x = i * 1200 + this.offset[j % 12]
          if (x >= 1200 * this.rowsMin && x <= (1200 * this.rowsMax) - 600) bounds.push([[x, y], [x + 200, y + 300]])
        }
      }
      return bounds
    },
    boundGridVertical() {
      let bounds = []
      for (let i = this.rowsMin; i < this.rowsMax; i++) {
        for (let j = this.colsMin; j < this.colsMax; j++) {
          let y = j * 100 // y is horizontal __
          let x = 200 + i * 1200 + this.offset[j % 12] // x is vertical |
          if (x >= (1200 * this.rowsMin) && x <= (1200 * this.rowsMax) - 600) bounds.push([[x, y], [x + 300, y + 200]])
        }
      }
      return bounds
    },
  },
  methods: {
    mapUpdate(e) {
      if (e._southWest.lat <= this.rectangleBounds[0][0]) this.setMoreTiles('down')
      else if (e._northEast.lat >= this.rectangleBounds[1][0]) this.setMoreTiles('up')
      if (e._southWest.lng <= this.rectangleBounds[0][1]) this.setMoreTiles('left')
      else if (e._northEast.lng >= this.rectangleBounds[1][1]) this.setMoreTiles('right')
    },
    setMoreTiles(dir) {
      if (dir === 'left') {
        this.colsMax -= 10
        this.colsMin -= 10
      } else if (dir === 'right') {
        this.colsMax += 10
        this.colsMin += 10
      } else if (dir === 'up') {
        this.rowsMax += 1
        this.rowsMin += 1
      } else if (dir === 'down') {
        this.rowsMax -= 1
        this.rowsMin -= 1
      }
    },
    getRandom(index, max = 50) {
      const value = Math.abs(Math.sin(index))
      return Math.floor(value * max)
    },
    getRandomImage(format, bounds) {
      if (format === 'horizontal') {
        const hash = bounds[0][0]
        const index = this.getRandom(hash, 50)
        const img = this.imagesHorizontal[index]
        if (img) return img.url
        else return 'https://ik.imagekit.io/1xhmiz6dd5b/k154_rafaelaschmid_ntKQ5jyJQ.jpg?updatedAt=1631800903417&tr=w-300,h-200,fo-auto'
      } else {
        const hash = bounds[1][0]
        const index = this.getRandom(hash, 50)
        const img = this.imagesVertical[index]
        if (img) return img.url
        else return 'https://ik.imagekit.io/1xhmiz6dd5b/k154_rafaelaschmid_ntKQ5jyJQ.jpg?updatedAt=1631800903417&tr=w-200,h-300,fo-auto'
      }
    },
    hoverImage(e) {
      if (e.target._url && e.target._url.includes('bl-12')) {
        const url = e.target._url.replace(',bl-12', '')
        e.sourceTarget.setUrl(url)
      }
    },
    focusImage(e) {
      this.hoverImage(e);
      // this.setMoreTiles('down')
      const zoomLevel = window.innerWidth <= 900 ? 0.2 : 1
      const imageWidth = e.sourceTarget._bounds._northEast.lng - e.sourceTarget._bounds._southWest.lng
      const imageHeight = e.sourceTarget._bounds._northEast.lat - e.sourceTarget._bounds._southWest.lat
      this.$refs.map.mapObject.setView([e.sourceTarget._bounds._southWest.lat + imageHeight / 2, e.sourceTarget._bounds._southWest.lng + imageWidth / 2], zoomLevel);
      // this.activeImage = e.target._url
    },
  },
  mounted() {
    const cols = this.colsMin + Math.floor((this.colsMax - this.colsMin) / 2)
    const rows = this.rowsMin + Math.floor((this.rowsMax - this.rowsMin) / 2)
    let x = rows * 1200 + 150
    let y = cols * 100 + 150
    this.$refs.map.mapObject.setView([x, y], 1);
    if (window.innerWidth <= 900) {
      this.minZoom = 0
      this.maxZoom = 1.5
      console.log(this.$refs.map.mapObject)
      this.$refs.map.mapObject.zoomOut(1);
    }

    document.oncontextmenu = function() {
      return false;
    }
  }
};
</script>

<style lang="scss">

.leaflet-container {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}


.leaflet-pane {
  img {
    opacity: 0.5;
    transition: opacity 350ms;
    object-fit: cover;
    z-index: -1;
    @media only screen and (max-width: 768px) {
      @media (pointer:coarse) {
        opacity: 1;
      }
    }
  }

  img::after {
    content: "";
    position: absolute;
    inset: 0 0 0 0;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  img:hover {
    opacity: 1;
  }
}

</style>
