<template>
  <main class="content" v-if="content">
    <h1>{{ content.title }}</h1>
    <Container v-if="content" :content="content.container"/>
  </main>
</template>

<script>
import storyblokMixin from '../mixins/storyblok';
import Container from "../components/Container";

export default {
  name: "Impressum",
  components: { Container },
  mixins: [
    storyblokMixin,
  ],
  metaInfo: {
    title: 'Impressum | Ausdrucksfotografie',
    htmlAttrs: {
      lang: 'de-CH'
    },
    meta: [
      {charset: 'utf-8'},
      {name: 'description', content: 'Spannende S/W-Bilder, von einer tollen Künstlerin fotografiert! Qualität, wird hier ganz gross geschrieben! Ausdrucksfotografie.ch by Caroline Staeger.'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'}
    ]
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">

main{
  margin: 9rem 0;
  @include page-padding;
}

</style>