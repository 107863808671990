<template>
  <aside>
    <a :href="getLink(link.link)" v-for="link in links" class="btn-link" :target="getTarget(link.link)">
      <span class="link small">
        <img :src="platformImage(link.platform)" :alt="`Finde Caroline Stäger auf ${link.platform}`"/>
      </span>
    </a>
  </aside>
</template>

<script>
import globalsStore from '../store/globals'

export default {
  name: "SocialMedia",
  computed: {
    links() {
      return globalsStore.getters.EXTERNAL_LINKS
    }
  },
  methods: {
    getLink(link) {
      if (link.email) return `mailto:${link.email}`
      else if (link.linktype === "url" && link.url.charAt(0) === '0') return `tel:${link.url}`
      else if (link.linktype === "url") return link.url
      else {
        const anchor = link.anchor ? `#${link.anchor}` : ""
        return link.cached_url + anchor
      }
    },
    getTarget(link) {
      return link.linktype === 'url' ? '_blank' : '_self'
    },
    platformImage(platform) {
      return require('../assets/images/icon-' + platform + ".svg")
    }
  }

}
</script>

<style scoped lang="scss">

aside {
  display: flex;
  justify-content: end;
  gap: 1rem;

  @include tablet-down {
    gap: 0.5rem;
  }
}


a {
  user-select: none;
}

span.link img {
  width: 92%;
  margin: 4%;
}

</style>