<template>
  <main class="content about">
    <div class="about-main">
      <Container v-if="content" :content="content.container_top"/>
    </div>
    <AboutImageComposition v-if="content" :images="content.images"/>

    <div class="container inverted" id="kontakt" ref="kontakt">
      <Container v-if="content" :content="content.container_bottom" @hook:mounted="scrollToListener"/>
    </div>
  </main>

</template>

<script>
import storyblokMixin from '../mixins/storyblok';
import Container from "../components/Container";
import AboutImageComposition from "../components/AboutImageComposition";

export default {
  name: "About",
  components: {AboutImageComposition, Container},
  mixins: [
    storyblokMixin,
  ],
  watch: {
    $route(to) {
      if (to.hash && to.hash.length > 1) this.scrollToAnchor(to.hash)
    }
  },
  methods: {
    scrollToAnchor(hash) {
      const element = document.querySelector(hash)
      if (element) {
        window.scrollTo({
          behavior: 'smooth',
          top: element.offsetTop - 200 || 0
        })
      }
    },
    scrollToListener(){
      if (this.$route.hash && this.$route.hash.length > 1) this.scrollToAnchor(this.$route.hash)
    }
  },
}
</script>

<style scoped lang="scss">

main {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1.5rem;
  background: $color_primary;
  color: #ffffff;

  p {
    color: #ffffff;
  }

  @include page-padding;

  @include tablet-down {
    display: block;
  }
}

main > > > a {
  color: #ffffff;

}

.about-main {
  padding-top: $header_height;
  //width: calc(100% - 4.5rem);
  //max-width: 23rem;
  //margin: auto 2rem auto auto;
  //
  //@include tablet-down{
  //  margin: 8rem 0 5rem 0;
  //  width: 100%;
  //  max-width: unset;
  //  @include page-padding;
  //}
}


div.container {
  padding: 0 0 5rem 0;
  grid-column-start: 1;
  grid-column-end: 3;


}


</style>


