<template>
  <main class="home">
    <Gallery/>
  </main>
</template>

<script>
import Gallery from "../components/Gallery";

export default {
  name: "Home",
  components: {Gallery},
  metaInfo: {
    title: 'Home | Ausdrucksfotografie',
    htmlAttrs: {
      lang: 'de-CH'
    },
    meta: [
      {charset: 'utf-8'},
      {
        name: 'description',
        content: 'Spannende S/W-Bilder, von einer tollen Künstlerin fotografiert! Qualität, wird hier ganz gross geschrieben! Ausdrucksfotografie.ch by Caroline Staeger.'
      },
      {name: 'viewport', content: 'width=device-width, initial-scale=1'}
    ]
  },
}
</script>

<style lang="scss" scoped>

main.home {

}

</style>
