<template>
  <article v-if="content" class="container">
    <component
      v-for="component in content"
      :key="component._uid"
      :is="component.component"
      :body="component"
    />
  </article>
</template>

<script>
export default {
  name: "Content",
  props: {
    content: Array
  }
}
</script>

<style scoped lang="scss">

.container{
  max-width: 40rem;

  >div{
    padding: 0 0 2rem 0;
  }
}


</style>