import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {LMap, LMarker, LTileLayer} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import StoryblokVue from 'storyblok-vue';
import ContainerText from "./components/container/ContainerText";
import ContainerPartners from "./components/container/ContainerPartners";
import VueCompositionApi from '@vue/composition-api';
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer';
import VueMeta from 'vue-meta'
import Vuex from 'vuex'
import VueHead from 'vue-head'


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Vue.use(StoryblokVue);

Vue.component('ContainerText', ContainerText)
Vue.component('ContainerPartners', ContainerPartners)

Vue.use(VueCompositionApi);
Vue.use(VueRichTextRenderer);

Vue.use(Vuex)



Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
