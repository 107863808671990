<template>
  <aside class="about image-composition">
    <img v-for="img in images" :src="transformImage(img.image, 1.5)" :alt="img.image.alt"/>
  </aside>
</template>

<script>
export default {
  name: "AboutImageComposition",
  props: {
    images: Array
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    transformImage(image, scale) {
      const IMAGE_SERVICE = "https://img2.storyblok.com/"
      const IMAGE_PREFIX = "https://a.storyblok.com"
      const VIEWPORT_WIDTH = 1000
      const SIZE = `${Math.floor(VIEWPORT_WIDTH * scale)}x0`
      const PATH = image.filename.replace(IMAGE_PREFIX, "");
      return IMAGE_SERVICE + SIZE + '/filters:format(webp)/' + PATH;
    }
  },

  mounted() {
  }
}
</script>

<style scoped lang="scss">

aside {
  display: grid;
  grid-template-columns: repeat(5, 9vw);
  grid-template-rows: repeat(6, 9vw);
  margin: 0 auto;
  max-height: 99vh;

  @include tablet-down{
    grid-template-columns: repeat(5, 16.5vw);
    grid-template-rows: repeat(6, 16.5vw);
    max-height: unset;
    margin-bottom: 2.5rem;
  }
  @include mobile-down{

  }
}

img:first-of-type {
  grid-area: 1 / 1 / 4 / 3;
}

img:nth-child(2) {
  grid-area: 2 / 3 / 4 / 6;
}


img:nth-child(3) {
  grid-area: 4 / 1 / 6 / 4;
}

img:nth-child(4) {
  grid-area: 4 / 4 / 7 / 6;
}


img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

</style>