<template>
  <footer>
    <p>{{ copyright }}, <a :href="imprint">Impressum</a></p>
  </footer>
</template>

<script>
import globalsStore from "../store/globals";

export default {
  name: "Footer",
  computed: {
    copyright() {
      return globalsStore.getters.COPYRIGHT
    },
    imprint() {
      return  globalsStore.getters.LINK_IMPRESSUM ? '/' + globalsStore.getters.LINK_IMPRESSUM.cached_url : null
    }
  }
}
</script>

<style scoped lang="scss">

footer {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: $general_padding;
  font-size: 0.8rem;
  background: black;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: #ffffff;
}

a:hover {
  border-bottom: 1px solid #ffffff;
}


</style>