<template>
  <div id="app" :class="{'home': isHome}">
    <header>
      <Navigation/>
      <Links/>
    </header>
    <router-view/>
    <Footer/>
    <cookie-law>
      <div slot-scope="props" class="cookie-wrapper">
        <div class="cookie-banner">
          <h4>Cookie Banner</h4>
          <p>
            Diese Webseite benützt Cookies. Funktionelle Cookies müssen für den Besuch dieser Webseite zwingend aktiv
            sein, damit beispielsweise Schriften korrekt angezeigt werden.
          </p>
          <div class="cookie-buttons">
            <button class="skew" @click="props.accept"><span>Alle Cookies akzeptieren</span></button>
            <button class="skew" @click="props.close"><span>Nur funktionelle Cookies aktivieren</span></button>
          </div>
        </div>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
import Links from "./components/Links";
import Footer from "./components/Footer";
import imageStore from './store/images'
import globalsStore from "./store/globals";
import CookieLaw from 'vue-cookie-law'


export default {
  components: {Footer, Links, Navigation, CookieLaw},
  data() {
    return {
      isHome: false,
      cookies: {
        text: "Diese Webseite benutzt Cookies.",
        acceptText: "Akzeptieren",
        linkText: "",
        link: "",
        customLink: "",
      }
    };
  },
  watch: {
    $route(to, from) {
      this.isHome = to.name === 'Home'
    }
  },
  created() {

    // fetch content
    imageStore.dispatch('fetchImages')
    globalsStore.dispatch('fetchContent')

    // storybridge
    if (this.$storyblok) {
      this.$storyblok.init();
      this.$storyblok.on([`change`, `published`], () => window.location.reload());
      this.$storyblok.on(`input`, ({story}) => {
        this.content = story.content;
      });
    }
  },
  mounted() {
    this.isHome = this.$route.name === 'Home'
  }
}
</script>

<style lang="scss">
#app {

}


header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: $header_height;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  padding: $general_padding;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0);

  @include tablet-down {
    padding: $general_padding_mobile;
  }
}

</style>
