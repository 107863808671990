<template>
  <main class="error-page">
    <h1>Diese Seite gibt es nicht</h1>
  </main>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped class="scss">

main{
  width: 100%;
  min-height: 99vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>