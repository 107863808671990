<template>
  <div v-if="body">
    <rich-text-renderer v-if="body.text" :document="body.text"/>
  </div>
</template>

<script>
export default {
  name: "ContainerText",
  props: {
    body: Object
  }
}
</script>

<style scoped lang="scss">



</style>