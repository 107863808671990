<template>
  <div class="partner-item">
    <a :href="partner.link.url || partner.link.cached_url" target="_blank">
      <img :src="partner.logo.filename" :alt="`Logo ${partner.name}, ${partner.title}`"/>
      <p><b>{{ partner.title }}</b></p>
      <p><b>{{ partner.name }}</b></p>
    </a>
  </div>
</template>

<script>
export default {
  name: "ItemPartner",
  props: {
    partner: Object
  }
}
</script>

<style scoped lang="scss">

.partner-item {
  width: 100%;
}

img {
  max-width: 100%;
  display: flex;
  margin: 2rem auto;
  min-height: 7rem;
}


a {

  img {
    opacity: 1;
    transform: scale(1);
    transition: opacity 350ms, transform 300ms;
  }
}

a:hover {
  text-decoration: none;
  border-bottom: none;

  img{
    opacity: 0.9;
    transform: scale(1.05);
  }
}

p {
  font-family: alternate-gothic-atf, 'Helvectia', 'Arial', sans-serif;
  font-size: 1rem;
  margin: 0.25rem 0;
  padding-left: 10%;
}

</style>