<template>
  <nav>
    <router-link :to="nav.route" class="logo-link btn-link">
      <span class="link logo"><img src="../assets/images/logo.svg" :alt="`Logo Caroline Staeger`"/></span>
      <span class="link-text">{{ nav.text }}</span>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      currentView: 'home',
      links: {home: {'text': 'Home', 'route': '/'}, about: {'text': 'About', 'route': '/about'}}
    }
  },
  computed: {
    nav() {
      const isNotHome = this.$route.name !== 'Home'
      return isNotHome ? this.links['home'] : this.links['about']
    }
  }
}
</script>

<style scoped lang="scss">


.logo-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 5ch;
}


.link-text {
  text-transform: uppercase;
  padding-top: 0.5rem;
}

span.link.logo {
  background-color: transparent;
}


</style>