<template>
  <div class="loading-screen" :class="{active: isLoading}" :style="{ backgroundColor: backgroundColor}">
    <img :src="logo.filename" alt="Logo Ausdrucksfotographie, Caroline Staeger" :class="{'visible': globalsLoaded}"/>
  </div>
</template>

<script>
import globalsStore from "../store/globals";


export default {
  name: "LoadingScreen",
  props: {
    isLoading: Boolean
  },
  computed: {
    backgroundColor() {
      return globalsStore.getters.LOADING_BACKGROUNDCOLOR || '#d20170'
    },
    logo() {
      return globalsStore.getters.LOADING_LOGO || false
    },
    globalsLoaded() {
      return globalsStore.getters.LOADED || false
    },
  }
}
</script>

<style scoped lang="scss">


.loading-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  background: pink;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 1500ms;
  transition-delay: 1000ms;
  cursor: wait;

  &.active {
    opacity: 1;
  }

  &:not(.active) {
    pointer-events: none;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    background-size: 300vw 300vh;
    background-position: 150vh 150vh;
    animation: move-background-position 8000ms;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }
}

img {
  width: 4rem;
  opacity: 0;
  transition: opacity 550ms;
  transition-delay: 100ms;

  &.visible {
    opacity: 1;

  }
}

@keyframes move-background-position {
  0% {
    background-position: 150vw 150vh;
  }

  25% {
    background-position: 200vw 170vh;
  }

  50% {
    background-position: 150vw 150vh;
  }

  75% {
    background-position: 129vw 190vh;
  }

  100% {
    background-position: 150vw 150vh;
  }
}

</style>